/**
 * prism.js Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 * Patched by Jakub T. Jankiewicz to fit jQuery Terminal
 */

.terminal .token.comment,
.cmd .token.comment,
.terminal-external .token.comment,
.terminal .token.block-comment,
.cmd .token.block-comment,
.terminal-external .token.block-comment,
.terminal .token.prolog,
.cmd .token.prolog,
.terminal-external .token.prolog,
.terminal .token.doctype,
.cmd .token.doctype,
.terminal-external .token.doctype,
.terminal .token.cdata,
.cmd .token.cdata,
.terminal-external .token.cdata {
  --color: rgba(49.02%, 54.51%, 60% , 0.997);
}

.terminal .token.punctuation,
.cmd .token.punctuation,
.terminal-external .token.punctuation {
  --color: rgba(37.255%, 38.824%, 39.216% , 0.997);
}

.terminal .token.property,
.cmd .token.property,
.terminal-external .token.property,
.terminal .token.tag,
.cmd .token.tag,
.terminal-external .token.tag,
.terminal .token.boolean,
.cmd .token.boolean,
.terminal-external .token.boolean,
.terminal .token.number,
.cmd .token.number,
.terminal-external .token.number,
.terminal .token.function-name,
.cmd .token.function-name,
.terminal-external .token.function-name,
.terminal .token.constant,
.cmd .token.constant,
.terminal-external .token.constant,
.terminal .token.symbol,
.cmd .token.symbol,
.terminal-external .token.symbol,
.terminal .token.deleted,
.cmd .token.deleted,
.terminal-external .token.deleted {
  --color: rgba(78.824%, 17.255%, 17.255% , 0.997);
}

.terminal .token.selector,
.cmd .token.selector,
.terminal-external .token.selector,
.terminal .token.attr-name,
.cmd .token.attr-name,
.terminal-external .token.attr-name,
.terminal .token.string,
.cmd .token.string,
.terminal-external .token.string,
.terminal .token.char,
.cmd .token.char,
.terminal-external .token.char,
.terminal .token.function,
.cmd .token.function,
.terminal-external .token.function,
.terminal .token.builtin,
.cmd .token.builtin,
.terminal-external .token.builtin,
.terminal .token.inserted,
.cmd .token.inserted,
.terminal-external .token.inserted {
  --color: rgba(18.431%, 61.176%, 3.9216% , 0.997);
}

.terminal .token.operator,
.cmd .token.operator,
.terminal-external .token.operator,
.terminal .token.entity,
.cmd .token.entity,
.terminal-external .token.entity,
.terminal .token.url,
.cmd .token.url,
.terminal-external .token.url,
.terminal .token.variable,
.cmd .token.variable,
.terminal-external .token.variable {
  --color: rgba(65.098%, 49.804%, 34.902% , 0.997);
}

.terminal .token.atrule,
.cmd .token.atrule,
.terminal-external .token.atrule,
.terminal .token.attr-value,
.cmd .token.attr-value,
.terminal-external .token.attr-value,
.terminal .token.keyword,
.cmd .token.keyword,
.terminal-external .token.keyword,
.terminal .token.class-name,
.cmd .token.class-name,
.terminal-external .token.class-name {
  --color: rgba(9.8039%, 56.471%, 72.157% , 0.997);
}

.terminal .token.regex,
.cmd .token.regex,
.terminal-external .token.regex,
.terminal .token.important,
.cmd .token.important,
.terminal-external .token.important {
  --color: rgba(93.333%, 60%, 0% , 0.997);
}

.terminal .language-css .token.string,
.cmd .language-css .token.string,
.terminal-external .language-css .token.string,
.terminal .style .token.string,
.cmd .style .token.string,
.terminal-external .style .token.string {
  --color: rgba(65.098%, 49.804%, 34.902% , 0.997);
}

.terminal .token.important,
.cmd .token.important,
.terminal-external .token.important {
  font-weight: normal;
}

.terminal .token.bold,
.cmd .token.bold,
.terminal-external .token.bold {
  font-weight: bold;
}

.terminal .token.italic,
.cmd .token.italic,
.terminal-external .token.italic {
  font-style: italic;
}

.terminal .token.entity,
.cmd .token.entity,
.terminal-external .token.entity {
  cursor: help;
}

.terminal .token.namespace,
.cmd .token.namespace,
.terminal-external .token.namespace {
  opacity: .7;
}
.terminal .token,
.cmd .token,
.terminal-external .token {
    color: var(--color);
    background: var(--background);
    --original-color: var(--color);
}
