/*!
 * Style Sheet for jQuery splitter Plugin version 0.29.1
 * Copyright (C) 2010-2020 Jakub T. Jankiewicz <https://jcubic.pl/me>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.splitter_panel {
  position: relative;
}
.splitter_panel .vsplitter {
    background-color: grey;
    cursor: col-resize;
    z-index: 900;
    width: 7px;
}

.splitter_panel .hsplitter {
    background-color: #5F5F5F;
    cursor: row-resize;
    z-index: 800;
    height: 7px;
}
.splitter_panel .vsplitter.splitter-invisible,
.splitter_panel .hsplitter.splitter-invisible {
    background: none;
}
.splitter_panel .vsplitter,
.splitter_panel .left_panel,
.splitter_panel .right_panel,
.splitter_panel .hsplitter,
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .horizontal_panel,
.splitter_panel .vertical_panel {
    position: absolute;
    overflow: auto;
}

.splitter_panel .vsplitter,
.splitter_panel .left_panel,
.splitter_panel .right_panel,
.splitter_panel .vertical_panel {
  height: 100%;
  box-sizing: border-box;
}
.splitter_panel .hsplitter,
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .horizontal_panel {
  width: 100%;
  box-sizing: border-box;
}
.splitter_panel .top_panel,
.splitter_panel .left_panel,
.splitter_panel .vsplitter,
.splitter_panel .vertical_panel {
   top: 0;
}
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .left_panel,
.splitter_panel .hsplitter,
.splitter_panel .horizontal_panel {
   left: 0;
}
.splitter_panel .bottom_panel {
   bottom: 0;
}
.splitter_panel .right_panel {
   right: 0;
}
.splitterMask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
